<template>
  <!--
    Used to display and / or visualise the videoDirectors data.
  -->
  <Portlet
    :title="$t('videoDirectorDetailsComp.videoDirector')"
    class="vidoDirectorDetails"
    icon="video"
  >
    <template slot="buttons">
      <label class="mr-2 d-block nowrap">{{ $t('imageInterval') }}:</label>
      <div class="btn-group btn-group-sm d-none d-md-block">
        <button
          v-for="(speed, index) in intervalSpeeds"
          :key="`videoDirectorDetails-speedButton-${ index }`"
          :class="`btn ${ speed.active && getImageBoolean ? 'btn-primary btn-sm' : 'btn-secondary' }`"
          @click="resetInterval(speed)"
        >
          {{ speed.speed }}ms
        </button>
        <button
          class="btn btn-sm btn-secondary"
          @click="getImageBoolean = false"
        >
          {{ $t('disable') }}
        </button>
      </div>
    </template>
    <LoadingPlaceholder v-if="loading" />
    <template v-else>
      <template v-if="laneConfig && image">
        <div class="row">
          <div class="col-12 col-md-6">
            <VideoDirectorConfigDefaultVisualiser
              ref="videoDirectorConfigDefaultVisualiser"
              :lane-config="laneConfig"
              :installation-id="installationId"
              :pt-head-position="status.currentPt"
              :display-all-axes="true"
              :preview-position="previewDebouncePosition"
              @getConfig="getConfig()"
              @changePtz="changePtz"
            />

            <div class="my-2">
              <span>{{ $t('videoDirectorDetailsComp.moveToInitArea') }}:&nbsp;</span>
              <div class="btn-group">
                <template v-for="(flowArea, index) in laneConfig.flowAreas">
                  <button
                    v-if="flowArea.active == true"
                    :key="`flowAreaButton${ index }InVideoDirectorDetails`"
                    class="btn btn-sm btn-secondary"
                    @click="changePtz({ pan: flowArea.initArea.position.pan, tilt: flowArea.initArea.position.tilt })"
                  >
                    {{ `${ index + 1 }. ${ flowArea.name }` }}
                  </button>
                </template>
              </div>
            </div>
          </div>

          <div class="col-12 col-md-6 mb-2">
            <div class="crosshair">
              <span class="horizontal" />
              <span class="vertical" />
              <img
                id="imageSelectorDirectorDetails"
                :src="`data:image/png;base64,${ image.base64Data }`"
                class="m-0 p-0"
                @click="moveToPosition"
              >
            </div>
          </div>
        </div>

        <div
          v-if="status"
          class="row"
        >
          <div class="col-12 col-sm-5 mb-2">
            <PanTiltVisualiser :status="status" />
          </div>
          <div class="col-12 col-sm-7 mb-2">
            <table class="defaultTable">
              <tbody>
                <tr>
                  <th>{{ $t('videoDirectorDetailsComp.currentPosition') }}:</th>
                  <td class="p-0 m-0 border-bottom-0">
                    <table class="defaultTable p-0 m-0 border-0">
                      <tbody>
                        <tr>
                          <th>{{ $t('pan') }}:</th>
                          <td>{{ status.currentPt.pan }}</td>
                        </tr>
                        <tr>
                          <th>{{ $t('tilt') }}:</th>
                          <td>{{ status.currentPt.tilt }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                <tr>
                  <th>{{ $t('videoDirectorDetailsComp.calculatedZoom') }}:</th>
                  <td>{{ status.calculatedZoom }}</td>
                </tr>
                <tr>
                  <th>{{ $t('videoDirectorDetailsComp.cameraOperatorStatus') }}:</th>
                  <td>{{ status.cameraOperatorOperationStatus }}</td>
                </tr>
                <tr>
                  <th>{{ $t('videoDirectorDetailsComp.videoRecorderOperationStatus') }}:</th>
                  <td>{{ status.videoRecorderOperationStatus }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </template>
      <p v-else>
        {{ $t('noDataAvailable') }}
      </p>
    </template>
    <router-link
      :to="`/installation/${ installationId }/tracker`"
      class="btn btn-secondary"
    >
      <font-awesome-icon
        class="mr-1"
        icon="caret-left"
      />
      <span>
        {{ $t('back') }}
      </span>
    </router-link>
  </Portlet>
</template>

<script>
import _ from 'lodash';

let that = this;

export default {
  name: "VideoDirectorDetails",
  components: {
    VideoDirectorConfigDefaultVisualiser: () => import('@/components/Config/VideoDirector/Visualiser/VideoDirectorConfigDefaultVisualiser.vue'),
    PanTiltVisualiser: () => import('@/components/VideoDirector/PanTiltVisualiser.vue'),
  },
  props: {
    videoRecorderId: {
      type: String,
      required: true
    },
    installationId: {
      type: String,
      required: true
    },
    laneNumber: {
      type: String,
      required: false,
      default () {
        return null;
      }
    }
  },
  data () {
    return {
      status: null,
      laneConfig: null,
      loading: true,
      image: {
        base64Data: null,
        fileName: null
      },
      focused: false,
      borderArea: null,
      imageIntervalVar: null,
      getImageBoolean: true,
      svgWidth: 340,
      svgHeight: 160,
      rootMatrix: { x: 1, y: 1 },
      ptzCancelSource: null,
      imageCancelSource: null,
      statusCancelSource: null,
      ptMoveUnits: {
        pan: 1,
        tilt: 1
      },
      debouncePosition: {
        pan: 0,
        tilt: 0
      },
      previewDebouncePosition: {
        pan: 0,
        tilt: 0
      },
      debouncing: false,
      eventMap: {},
      focusPointInterval: null,
      intervalSpeeds: [
        { speed: 100, active: false },
        { speed: 200, active: false },
        { speed: 300, active: false },
        { speed: 400, active: false },
        { speed: 500, active: true },
        { speed: 1000, active: false },
        { speed: 2000, active: false }
      ]
    };
  },
  created () {
    this.getData();
    this.checkFocus();
    that = this;
  },
  updated () {
    if (this.$refs == null) {
      return;
    }
    if (this.$refs.videoDirectorSvg == null) {
      return;
    }
    let rootRect = this.$refs.videoDirectorSvg.getBoundingClientRect();
    this.rootMatrix = {
      x: this.svgWidth / rootRect.width,
      y: this.svgHeight / rootRect.height
    };
  },
  beforeDestroy () {
    this.cancelInterval();
    window.onkeypress = window.onkeydown = window.onkeyup = null;
    this.cancelPtzRequest();
    this.cancelImageRequest();
    this.cancelFocusPointInterval();
  },
  methods: {
//#region Helpers
    createFocusPointInterval () {
      this.focusPointInterval = window.setInterval (() => {
        this.moveFocusPoint();
      }, 100 );
    },
    cancelFocusPointInterval () {
      this.debouncing = false;
      window.clearInterval(this.focusPointInterval);
      this.focusPointInterval = null;
    },
    moveFocusPoint () {
      if (Object.values(this.eventMap).indexOf(true) < 0) {
        return;
      }
      if (this.eventMap['38'] == true) {
        this.debouncePosition.tilt += this.ptMoveUnits.tilt;
      }
      if (this.eventMap['40'] == true) {
        this.debouncePosition.tilt -= this.ptMoveUnits.tilt;
      }
      if (this.eventMap['37'] == true) {
        this.debouncePosition.pan -= this.ptMoveUnits.pan;
      }
      if (this.eventMap['39'] == true) {
        this.debouncePosition.pan += this.ptMoveUnits.pan;
      }
      this.previewDebounce();
      this.changePtzDebounced();
    },
    checkFocus () {
      if (document.hasFocus() == false) {
        window.focus();
      }
      this.createFocusPointInterval();
      window.onkeypress = window.onkeydown = window.onkeyup = this.checkArrowKeys;
    },
    previewDebounce () {
      this.debouncing = true;
      this.previewDebouncePosition = {
        pan: this.status.currentPt.pan + this.debouncePosition.pan,
        tilt: this.status.currentPt.tilt + this.debouncePosition.tilt
      }
    },
    checkArrowKeys (event) {
      if (this.status == null) {
        return;
      }
      if (this.status.currentPt == null) {
        return;
      }
      if (this.status.currentPt.pan == null) {
        return;
      }
      event = event || window.event;
      this.eventMap[event.keyCode] = event.type != 'keyup';
    },
    createPolygons () {
      this.borderArea = "";
      for (let i = 0; i < this.laneConfig.borderArea.length; i++) {
        this.borderArea += "" + this.laneConfig.borderArea[i].pan + "," + this.laneConfig.borderArea[i].tilt;
      }
    },
    createPolygon (item) {
      let polygon = "";
      item.forEach(element => {
        if (element.x) {
          polygon += element.x + ',';
          polygon += element.y + ' ';
        } else {
          if (element.pan) {
            polygon += element.pan + ',';
            polygon += element.tilt + ' ';
          }
        }
      });
      return polygon;
    },
//#endregion

//#region API-intervals
    resetInterval (speed) {
      this.cancelInterval();
      this.createInterval(speed);
    },
    cancelInterval () {
      this.getImageBoolean = false;
      window.clearInterval(this.imageIntervalVar);
      this.imageIntervalVar = null;
      this.loading = false;
    },
    createInterval (speed) {
      this.imageIntervalVar = window.setInterval (() => {
        this.getImage();
        this.getStatus();
      }, speed.speed);
      this.intervalSpeeds.forEach(s => {
        s.active = false;
      });
      speed.active = true;
      this.getImageBoolean = true;
    },
    cancelPtzRequest () {
      if (this.ptzCancelSource) {
        this.ptzCancelSource.cancel();
      }
    },
    cancelStatusRequest () {
      if (this.statusCancelSource) {
        this.statusCancelSource.cancel();
      }
    },
    cancelImageRequest () {
      if (this.imageCancelSource) {
        this.imageCancelSource.cancel();
      }
    },
//#endregion

//#region API-calls
    changePtzFromEvent (event) {
      if (this.$refs == null) {
        return;
      }
      if (this.$refs.videoDirectorSvg == null) {
        return;
      }
      let rect = this.$refs.videoDirectorSvg.getBoundingClientRect();
      let pan = Math.floor((event.clientX - rect.left) * this.rootMatrix.x - this.svgWidth / 2);
      let tilt = -Math.floor((event.clientY - rect.top) * this.rootMatrix.y - this.svgHeight / 2);
      this.changePtz({ pan: pan, tilt: tilt });
    },
    changePtz (position) {
      this.cancelPtzRequest();
      this.ptzCancelSource = this.axios.CancelToken.source();
      let requestConfig = { cancelToken: this.ptzCancelSource.token };
      let url = `/VideoDirector/ChangePtz?installationId=${ this.installationId }&recorderId=${ this.videoRecorderId }`; 
      if (this.laneNumber)
      {
        url += `&laneNumber=${ this.laneNumber }`;
      }
      this.axios.post(url, position, requestConfig)
        .then((response) => {
          if (response) {
            this.ptzCancelSource = null;
          }
        })
        .catch(() => {
          if (this.axios.isCancel()) {
            return;
          }
        });
    },
    moveToPosition (event)
    {
      let image = document.getElementById('imageSelectorDirectorDetails');
      let widthPercentage = event.offsetX  / image.width * 100;
      let heightPercentage = event.offsetY / image.height * 100;
      let moveToPositionDto =
      {
        'widthPercentage': widthPercentage,
        'heightPercentage': heightPercentage
      };
      let url = `/VideoDirector/MoveToPosition?installationId=${ this.installationId }&videoRecorderId=${ this.videoRecorderId }`; 
      if (this.laneNumber)
      {
        url += `&laneNumber=${ this.laneNumber }`;
      }
      this.axios.post(url, moveToPositionDto)
        .then(() => {
        })
        .catch((error) => {
          this.$snotify.error(this.$t('videoDirectorConfigRecorderFormComponent.movedCameraError'));
          this.error_clear();
          this.error_validate(error);
        });
    },
    getStatus () {
      if (this.debouncing == true) {
        return;
      }
      this.cancelStatusRequest();
      this.statusCancelSource = this.axios.CancelToken.source();
      let requestConfig = { cancelToken: this.statusCancelSource.token };
      let url = `/VideoDirector/GetStatus?installationId=${ this.installationId }&videoRecorderId=${ this.videoRecorderId }`; 
      if (this.laneNumber)
      {
        url += `&laneNumber=${ this.laneNumber }`;
      }
      this.axios.get(url, requestConfig)
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          if (typeof response.data === 'object') {
            this.status = response.data;
            this.previewDebouncePosition = this.status.currentPt;
          }
        })
        .catch(() => {
          if (this.axios.isCancel()) {
            return;
          }
        })
        .finally(() => {
          this.focused = true;
        });
    },
    getData () {
      if (this.debouncing == true) {
        return;
      }
      let url = `/Config/GetLaneConfig?installationId=${ this.installationId }&videoRecorderId=${ this.videoRecorderId }`; 
      if (this.laneNumber)
      {
        url += `&laneNumber=${ this.laneNumber }`;
      }
      this.axios.get(url)
        .then((response) => {
          if (response && response.data) {
            this.laneConfig = response.data;
          }
          if (this.laneConfig) {
            this.createInterval(this.intervalSpeeds[3]);
            this.createPolygons();
          }
        })
        .finally(() => {
          window.setTimeout(() => {
            this.loading = false;
          }, 1250);
        });
    },
    getImage () {
      if (this.getImageBoolean == false) {
        return null;
      }
      this.cancelImageRequest();
      this.imageCancelSource = this.axios.CancelToken.source();
      let requestConfig = { cancelToken: this.imageCancelSource.token };
      let url = `/VideoDirector/GetImage?installationId=${ this.installationId }&id=${ this.videoRecorderId }`; 
      if (this.laneNumber)
      {
        url += `&laneNumber=${ this.laneNumber }`;
      }
      this.axios.get(url, requestConfig)
        .then((response) => {
          if (response == null) {
            this.cicleThroughSpeeds();
            return;
          }
          if (response.data == null) {
            this.cicleThroughSpeeds();
            return;
          }
          if (response.status == null) {
            this.cicleThroughSpeeds();
            return;
          }
          if (response.status != 200) {
            this.cicleThroughSpeeds();
            return;
          }
          this.ptzCancelSource = null;
          this.image = response.data;
          this.loading = false;
        })
        .catch(() => {
          if (this.axios.isCancel()) {
            return;
          }
          this.cancelInterval();
          this.loading = false;
        });
    },
    cicleThroughSpeeds () {
      let activeSpeed = this.intervalSpeeds.find(o => o.active == true);
      let activeIndex = this.intervalSpeeds.indexOf(activeSpeed);
      if (this.intervalSpeeds.length == activeIndex + 1) {
        this.getImageBoolean = false;
        this.loading = false;
      } else {
        this.intervalSpeeds[activeIndex].active = false;
        this.intervalSpeeds[activeIndex + 1].active = true;
        this.resetInterval(this.intervalSpeeds[activeIndex + 1]);
      }
    },
    changePtzDebounced: _.debounce(() => {
      let position = {
        pan: that.status.currentPt.pan + that.debouncePosition.pan,
        tilt: that.status.currentPt.tilt + that.debouncePosition.tilt
      }
      that.cancelPtzRequest();
      that.ptzCancelSource = that.axios.CancelToken.source();
      let requestConfig = { cancelToken: that.ptzCancelSource.token };
      let url = `/VideoDirector/ChangePtz?installationId=${ that.installationId }&recorderId=${ that.videoRecorderId }`; 
      if (this.laneNumber)
      {
        url += `&laneNumber=${ this.laneNumber }`;
      }
      that.axios.post(url, position, requestConfig)
        .then((response) => {
          if (response) {
            that.ptzCancelSource = null;
          }
        })
        .catch(() => {
          if (this.axios.isCancel()) {
            return;
          }
        })
        .finally(() => {
          that.debouncePosition.pan = 0;
          that.debouncePosition.tilt = 0;
          that.debouncing = false;
        });
    }, 500)
//#endregion
  }
}
</script>

<style scoped>
img {
  min-height: auto;
  margin-top: 1rem;
  width: 100%;
}
.zTitle {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -30%);
}
.m-badge.m-badge--brand.m-badge--wide {
  margin: 0 0.5rem 0.75rem 0;
}
.zoomPointRect {
  fill: green;
}
.boolLabel {
  position: relative;
  top: -1.5rem;
}
.nowrap {
  white-space: nowrap;
}
</style>
